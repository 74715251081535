// Imports
import Vue from 'vue';
import Router from 'vue-router';
import { trailingSlash } from '@/util/helpers';
import { layout, route } from '@/util/routes';
import store from '@/store/index'
import { get } from 'vuex-pathify'

Vue.use(Router);

const router = new Router({
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    route('Login Page'),

    layout('Default', [

      route('Tenant List',null, 'tenantList'),

      // Tables
      route('Report Tables', null, 'report'),
      route('Service Accounts', null, 'serviceAccounts'),
      route('Add Merchant',null, 'addMerchant')
    ]),
  ],
});

//增加導航守衛
router.beforeEach((to,from,next) => {
  if(to.name !== "Login Page" && store.get('app/globalToken') == null )
  {
    next({ name: 'Login Page' }) 
  } else {
    next()
  }
})

export default router;
