import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import './plugins';
import store from './store';
import VueToast from 'vue-toast-notification';
import filters from './filters';
import { HalfCircleSpinner } from 'epic-spinners';
// import i18n from './locales/index'
import jQuery from 'jquery';
import VueI18n from 'vue-i18n'
import cn from './locales/cn/cn.json'

import 'vue-toast-notification/dist/theme-default.css';

// const host = window.location.host;
// const protocol = window.location.protocol;

Vue.use(VueI18n);


Object.keys(filters).forEach((k) => Vue.filter(k, filters[k]));
Object.assign(window, { $: jQuery, jQuery });

Vue.component('HalfCircleSpinner', HalfCircleSpinner);

Vue.prototype.baseURL = `https://mgmtportal.asyf003.com/`;
Vue.config.productionTip = false;
Vue.use(VueToast);
sync(store, router);


const i18n = new VueI18n({
  locale: 'cn', // 預設語言
  fallbackLocale: 'cn', // 備用語言
  messages: cn
})

new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
